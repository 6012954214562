import {
  DeviceConfigurationsResponse,
  SchedulerConfig,
} from "../../../../../app/api/models";
import { Injectable } from "@angular/core";
import {
  ControllerSettings,
  ConfigurationKeys,
  Configuration,
  ConfigurationType,
  FlushScheduleConfiguration,
  FwCommMethod,
  FwActionInContinuous,
  FwSensorType,
  FwAINType,
  SensorUnits,
  FwAINMode,
  FwFlushSequence,
  FwSystemUnits,
  FwWaterMeterRole,
  CycleRelayRole,
  PistonPressureSensorRole,
  PressureSensorRole,
  FwWaterMeterUnits,
} from "amiad-shared-lib";
import { Adapter } from "../../../../../app/shared/api-models/controller/iadapter.interface";

@Injectable()
export class ApiControllerSettingsAdapterService
  implements Adapter<ControllerSettings> {
  constructor() { }
  adapt(item): ControllerSettings {
    if (item) {
      var settings: ControllerSettings = {
        CONFIG_ID_FLUSH_METHOD_INTERVAL_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.IntervalEnable,
          item.CONFIG_ID_FLUSH_METHOD_INTERVAL_ENABLE
        ),
        CONFIG_ID_CONTROLLER_STATUS: this.adaptConfig<boolean>(
          ConfigurationKeys.ControllerStatus,
          item.CONFIG_ID_CONTROLLER_STATUS
        ),
        CONFIG_ID_FLUSH_METHOD_DP_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.DpEnable,
          item.CONFIG_ID_FLUSH_METHOD_DP_ENABLE
        ),
        CONFIG_ID_DP_SET_POINT: this.adaptConfig<number>(
          ConfigurationKeys.DpSetPoint,
          item.CONFIG_ID_DP_SET_POINT
        ),
        CONFIG_ID_DWELL_TIME: this.adaptConfig<number>(
          ConfigurationKeys.DwellTime,
          item.CONFIG_ID_DWELL_TIME
        ),
        CONFIG_ID_INTERVAL: this.adaptConfig<number>(
          ConfigurationKeys.Interval,
          item.CONFIG_ID_INTERVAL
        ),
        CONFIG_ID_FLUSH_METHOD_SCHEDULE_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.ScheduleEnable,
          item.CONFIG_ID_FLUSH_METHOD_SCHEDULE_ENABLE
        ),
        CONFIG_ID_SCHEDULER_1: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler1,
          item.CONFIG_ID_SCHEDULER_1
        ),
        CONFIG_ID_SCHEDULER_2: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler2,
          item.CONFIG_ID_SCHEDULER_2
        ),
        CONFIG_ID_SCHEDULER_3: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler3,
          item.CONFIG_ID_SCHEDULER_3
        ),
        CONFIG_ID_SCHEDULER_4: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler4,
          item.CONFIG_ID_SCHEDULER_4
        ),
        CONFIG_ID_SCHEDULER_5: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler5,
          item.CONFIG_ID_SCHEDULER_5
        ),
        CONFIG_ID_SCHEDULER_6: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler6,
          item.CONFIG_ID_SCHEDULER_6
        ),
        CONFIG_ID_SCHEDULER_7: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler7,
          item.CONFIG_ID_SCHEDULER_7
        ),
        CONFIG_ID_SCHEDULER_8: this.adaptSchedulerConfig(
          ConfigurationKeys.Scheduler8,
          item.CONFIG_ID_SCHEDULER_8
        ),
        CONFIG_ID_FLUSH_TIME: this.adaptConfig<number>(
          ConfigurationKeys.FlushTime,
          item.CONFIG_ID_FLUSH_TIME
        ),
        CONFIG_ID_NUMBER_OF_FILTRATION_UNITS: this.adaptConfig<number>(
          ConfigurationKeys.NumberOfFiltrationUnits,
          item.CONFIG_ID_NUMBER_OF_FILTRATION_UNITS
        ),
        CONFIG_ID_COMM_METHOD: this.adaptConfig<FwCommMethod>(
          ConfigurationKeys.CommunicationMethod,
          item.CONFIG_ID_COMM_METHOD
        ),
        CONFIG_ID_CLOUD_COMM_TIME: this.adaptConfig<number>(
          ConfigurationKeys.CommunicationTime,
          item.CONFIG_ID_CLOUD_COMM_TIME
        ),
        CONFIG_ID_TOM_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.TomEnable,
          item.CONFIG_ID_TOM_ENABLE
        ),
        CONFIG_ID_TOM_THRESHOLD: this.adaptConfig<number>(
          ConfigurationKeys.TomThreshold,
          item.CONFIG_ID_TOM_THRESHOLD
        ),
        CONFIG_ID_RTU_ADDRESS: this.adaptConfig<number>(
          ConfigurationKeys.RTUAddress,
          item.CONFIG_ID_RTU_ADDRESS
        ),
        CONFIG_ID_NUMBER_OF_SLAVES: this.adaptConfig<number>(
          ConfigurationKeys.NumberOfSlaves,
          item.CONFIG_ID_NUMBER_OF_SLAVES
        ),
        CONFIG_ID_DP_STABILIZATION_TIME: this.adaptConfig<number>(
          ConfigurationKeys.DpStabilizationTime,
          item.CONFIG_ID_DP_STABILIZATION_TIME
        ),
        CONFIG_ID_DP_DELAY: this.adaptConfig<number>(
          ConfigurationKeys.DpDelay,
          item.CONFIG_ID_DP_DELAY
        ),
        CONFIG_ID_ACTION_IN_CONTINUOUS: this.adaptConfig<FwActionInContinuous>(
          ConfigurationKeys.ActionInContinuous,
          item.CONFIG_ID_ACTION_IN_CONTINUOUS
        ),
        CONFIG_ID_AIN1_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.AnalogInput1,
          item.CONFIG_ID_AIN1_ENABLE
        ),
        CONFIG_ID_AIN1_TYPE: this.adaptConfig<FwAINType>(
          ConfigurationKeys.AnalogInput1Type,
          item.CONFIG_ID_AIN1_TYPE
        ),
        CONFIG_ID_AIN1_MAX: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInput1MaxValue,
          item.CONFIG_ID_AIN1_MAX
        ),
        CONFIG_ID_AIN1_MIN: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInput1MinValue,
          item.CONFIG_ID_AIN1_MIN
        ),
        CONFIG_ID_AIN1_SENSOR: this.adaptConfig<FwSensorType>(
          ConfigurationKeys.AnalogInput1SensorType,
          item.CONFIG_ID_AIN1_SENSOR
        ),
        // CONFIG_ID_AIN1_UNIT: this.adaptConfig<SensorUnits>(ConfigurationKeys.AnalogInput1UnitOfMeasurements, item.CONFIG_ID_AIN1_UNIT),
        CONFIG_ID_AIN2_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.AnalogInput2,
          item.CONFIG_ID_AIN2_ENABLE
        ),
        CONFIG_ID_AIN2_TYPE: this.adaptConfig<FwAINType>(
          ConfigurationKeys.AnalogInput2Type,
          item.CONFIG_ID_AIN2_TYPE
        ),
        CONFIG_ID_AIN2_MAX: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInput2MaxValue,
          item.CONFIG_ID_AIN2_MAX
        ),
        CONFIG_ID_AIN2_MIN: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInput2MinValue,
          item.CONFIG_ID_AIN2_MIN
        ),
        CONFIG_ID_AIN2_SENSOR: this.adaptConfig<FwSensorType>(
          ConfigurationKeys.AnalogInput2SensorType,
          item.CONFIG_ID_AIN2_SENSOR
        ),
        // CONFIG_ID_AIN2_UNIT: this.adaptConfig<SensorUnits>(ConfigurationKeys.AnalogInput2UnitOfMeasurements, item.CONFIG_ID_AIN2_UNIT),
        CONFIG_ID_AIN_MODE: this.adaptConfig<FwAINMode>(
          ConfigurationKeys.AnalogInputMode,
          item.CONFIG_ID_AIN_MODE
        ),
        CONFIG_ID_AIN_SAMPLE_INTERVAL: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInputSamplingInterval,
          item.CONFIG_ID_AIN_SAMPLE_INTERVAL
        ),
        CONFIG_ID_AIN_STABILIZATION_TIME: this.adaptConfig<number>(
          ConfigurationKeys.AnalogInputStabilizationtime,
          item.CONFIG_ID_AIN_STABILIZATION_TIME
        ),
        CONFIG_ID_ALARM_RELAY_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.AlarmRelay,
          item.CONFIG_ID_ALARM_RELAY_ENABLE
        ),
        CONFIG_ID_DOWN_STREAM_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.DownstreamValve,
          item.CONFIG_ID_DOWN_STREAM_ENABLE
        ),
        // CONFIG_ID_DOWN_STREAM_DELAY: this.adaptConfig<number>(
        //   ConfigurationKeys.DownstreamValveDelay,
        //   item.CONFIG_ID_DOWN_STREAM_DELAY
        // ),
        CONFIG_ID_DOWN_STREAM_VALVE_ADDRESS: this.adaptConfig<number>(
          ConfigurationKeys.DownstreamValveAddress,
          item.CONFIG_ID_DOWN_STREAM_VALVE_ADDRESS
        ),
        CONFIG_ID_DP_ADDRESS: this.adaptConfig<number>(
          ConfigurationKeys.SystemDpAddress,
          item.CONFIG_ID_DP_ADDRESS
        ),
        CONFIG_ID_END_OF_CYCLE_LENGTH: this.adaptConfig<number>(
          ConfigurationKeys.EndOfCycle,
          item.CONFIG_ID_END_OF_CYCLE_LENGTH
        ),
        CONFIG_ID_EOC_RELAY_OUTPUT: this.adaptConfig<boolean>(
          ConfigurationKeys.EocRelayOutput,
          item.CONFIG_ID_EOC_RELAY_OUTPUT
        ),
        CONFIG_ID_EXTERNAL_DP_ADDRESS: this.adaptConfig<number>(
          ConfigurationKeys.ExternalDpAddress,
          item.CONFIG_ID_EXTERNAL_DP_ADDRESS
        ),
        CONFIG_ID_FLUSH_SEQUENCE: this.adaptConfig<FwFlushSequence>(
          ConfigurationKeys.FlushSequence,
          item.CONFIG_ID_FLUSH_SEQUENCE
        ),
        CONFIG_ID_FREEZING_PROTECT: this.adaptConfig<boolean>(
          ConfigurationKeys.AntifreezeProtect,
          item.CONFIG_ID_FREEZING_PROTECT
        ),
        CONFIG_ID_HIGH_DP_ALARM_THRESHOLD: this.adaptConfig<number>(
          ConfigurationKeys.HighDpAlarm,
          item.CONFIG_ID_HIGH_DP_ALARM_THRESHOLD
        ),
        CONFIG_ID_LOW_TEMPERATURE_THRESHOLD: this.adaptConfig<number>(
          ConfigurationKeys.AntifreezeTemperature,
          item.CONFIG_ID_LOW_TEMPERATURE_THRESHOLD
        ),
        CONFIG_ID_MINIMUM_INTERVAL_FOR_CONTINUOUS: this.adaptConfig<number>(
          ConfigurationKeys.MinimumIntervalForContinuous,
          item.CONFIG_ID_MINIMUM_INTERVAL_FOR_CONTINUOUS
        ),
        CONFIG_ID_NUMBER_OF_CONSECUTIVE_CYCLES: this.adaptConfig<number>(
          ConfigurationKeys.NumberOfConsecutiveCycles,
          item.CONFIG_ID_NUMBER_OF_CONSECUTIVE_CYCLES
        ),
        CONFIG_ID_PROTECTING_FLUSHING_INTERVAL: this.adaptConfig<number>(
          ConfigurationKeys.AntifreezeFlushingInterval,
          item.CONFIG_ID_PROTECTING_FLUSHING_INTERVAL
        ),
        CONFIG_ID_SOLENOID_LENGTH: this.adaptConfig<number>(
          ConfigurationKeys.SolenoidPulseLength,
          item.CONFIG_ID_SOLENOID_LENGTH
        ),
        CONFIG_ID_SYSTEM_UNITS: this.adaptConfig<FwSystemUnits>(
          ConfigurationKeys.SystemUnits,
          item.CONFIG_ID_SYSTEM_UNITS
        ),
        CONFIG_ID_WMETER1_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.WaterMeter1,
          item.CONFIG_ID_WMETER1_ENABLE
        ),
        CONFIG_ID_WMETER1_PULSE_UNITS: this.adaptConfig<FwWaterMeterUnits>(
          ConfigurationKeys.WaterMeter1Unit,
          item.CONFIG_ID_WMETER1_PULSE_UNITS
        ),
        CONFIG_ID_WMETER1_VOLUME: this.adaptConfig<number>(
          ConfigurationKeys.WaterMeter1PulseVolume,
          item.CONFIG_ID_WMETER1_VOLUME
        ),
        CONFIG_ID_WMETER2_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.WaterMeter2,
          item.CONFIG_ID_WMETER2_ENABLE
        ),
        CONFIG_ID_WMETER2_PULSE_UNITS: this.adaptConfig<FwWaterMeterUnits>(
          ConfigurationKeys.WaterMeter2Unit,
          item.CONFIG_ID_WMETER2_PULSE_UNITS
        ),
        CONFIG_ID_WMETER2_VOLUME: this.adaptConfig<number>(
          ConfigurationKeys.WaterMeter2PulseVolume,
          item.CONFIG_ID_WMETER2_VOLUME
        ),
        CONFIG_ID_IRRIGATION_LOG_DP_THRESHOLD: this.adaptConfig<number>(
          ConfigurationKeys.IrrigationLogDpThreshold,
          item.CONFIG_ID_IRRIGATION_LOG_DP_THRESHOLD
        ),
        CONFIG_ID_FLUSH_METHOD_WATER_FLOW_ENABLE: this.adaptConfig<boolean>(
          ConfigurationKeys.FlushMethodWaterFlowEnable,
          item.CONFIG_ID_FLUSH_METHOD_WATER_FLOW_ENABLE
        ),
        CONFIG_ID_FLUSH_METHOD_WATER_FLOW_THRESHOLD: this.adaptConfig<number>(
          ConfigurationKeys.FlushMethodWaterFlowThreshold,
          item.CONFIG_ID_FLUSH_METHOD_WATER_FLOW_THRESHOLD
        ),
        CONFIG_ID_DP_SAMPLING_INTERVAL: this.adaptConfig<number>(
          ConfigurationKeys.DPSampleInterval,
          item.CONFIG_ID_DP_SAMPLING_INTERVAL
        ),
        CONFIG_ID_WMETER_LOG_INTERVAL: this.adaptConfig<number>(
          ConfigurationKeys.WaterMeterLogInterval,
          item.CONFIG_ID_WMETER_LOG_INTERVAL
        ),
        CONFIG_ID_DOWN_STREAM_DELAY_CLOSE: this.adaptConfig<number>(
          ConfigurationKeys.DownstreamValveDelayClose,
          item.CONFIG_ID_DOWN_STREAM_DELAY_CLOSE
        ),
        CONFIG_ID_DOWN_STREAM_DELAY_OPEN: this.adaptConfig<number>(
          ConfigurationKeys.DownstreamValveDelayOpen,
          item.CONFIG_ID_DOWN_STREAM_DELAY_OPEN
        ),
        CONFIG_ID_CYCLE_RELAY_ROLE: this.adaptConfig<CycleRelayRole>(
          ConfigurationKeys.CycleRelayRole,
          item.CONFIG_ID_CYCLE_RELAY_ROLE
        ),
        CONFIG_ID_PRESSURE_SENSOR_1_ROLE: this.adaptConfig<PressureSensorRole>(
          ConfigurationKeys.PressureSensor1Role,
          item.CONFIG_ID_PRESSURE_SENSOR_1_ROLE
        ),
        CONFIG_ID_PRESSURE_SENSOR_3_ROLE: this.adaptConfig<PressureSensorRole>(
          ConfigurationKeys.PressureSensor2Role,
          item.CONFIG_ID_PRESSURE_SENSOR_3_ROLE
        ),
        CONFIG_ID_PRESSURE_SENSOR_PISTON_ROLE:
          this.adaptConfig<PistonPressureSensorRole>(
            ConfigurationKeys.PistonPressureSensorRole,
            item.CONFIG_ID_PRESSURE_SENSOR_PISTON_ROLE
          ),
        iccid: this.adaptConfig<string>(
          ConfigurationKeys.iccid,
          item.iccid
        ),
        CONFIG_ID_ALERTS_ENTER_ALARM: this.adaptConfig<string[]>(
          ConfigurationKeys.AlertEnterAlarm,
          item.CONFIG_ID_ALERTS_ENTER_ALARM
        )
      };

      return settings;
    }
    return null;
  }

  private adaptConfig<T extends ConfigurationType>(
    key: ConfigurationKeys,
    apiConfig
  ) {
    var appConfig: Configuration<T> = {
      key: key,
      value: null,
      pendingValue: null,
    };

    if (apiConfig != null) {
      appConfig.value = apiConfig.reported;
      appConfig.pendingValue = apiConfig.pending;
    }

    return appConfig;
  }

  private adaptSchedulerConfig(key: ConfigurationKeys, apiSchedConfig) {
    if (!this.isSchedulerKey(key)) {
      return null;
    }

    var appSchedConfig: Configuration<FlushScheduleConfiguration> = {
      key: key,
      value: null,
      pendingValue: null,
    };

    if (apiSchedConfig != null) {
      appSchedConfig.value = this.adaptSchedulerValue(apiSchedConfig.reported);
      appSchedConfig.pendingValue = this.adaptSchedulerValue(
        apiSchedConfig.pending
      );
    }

    return appSchedConfig;
  }

  private adaptSchedulerValue(
    configValue: SchedulerConfig
  ): FlushScheduleConfiguration {
    if (configValue) {
      var isActive = configValue.isEnabled;
      var schedDetails = configValue.schedule;

      var schedConfig: FlushScheduleConfiguration = {
        active: isActive ? true : false,
        schedule: {
          daily: schedDetails ? schedDetails.isDaily : false,
          hours: schedDetails ? schedDetails.hour : 0,
          minute: schedDetails ? schedDetails.minute : 0,
        },
      };

      // var schedConfig: FlushScheduleConfiguration = {
      //   active: configValue.isEnabled ? true : false,
      // }

      // if (configValue.isEnabled && configValue.schedule) {
      //   schedConfig.schedule = {
      //     daily: configValue.schedule.isDaily,
      //     hours: configValue.schedule.hour,
      //     minute: configValue.schedule.minute,
      //   }
      // }
      return schedConfig;
    } else {
      return null;
    }
  }
  private isSchedulerKey(key: ConfigurationKeys) {
    return (
      key === ConfigurationKeys.Scheduler1 ||
      key === ConfigurationKeys.Scheduler2 ||
      key === ConfigurationKeys.Scheduler3 ||
      key === ConfigurationKeys.Scheduler4 ||
      key === ConfigurationKeys.Scheduler5 ||
      key === ConfigurationKeys.Scheduler6 ||
      key === ConfigurationKeys.Scheduler7 ||
      key === ConfigurationKeys.Scheduler8
    );
  }
}

import { HistoryService, ConfigurationService } from "../../../api/services";
import { combineLatest, ReplaySubject, of } from "rxjs";
import { StatusService } from "../../../../app/api/services";
import { map, take, catchError } from "rxjs/operators";
import { LoggerService } from "ng-trio-infra";
import { CommunicationMode } from "amiad-shared-lib";
import { ControllerSettingsResponse } from "../../api-models/controller/controller-settings-response.model";
import { ControllerUtilService } from "./controller-util.service";
import { ControllerResponse, ControllersResponse, UnregisteredControllersResponse, } from "../../api-models/controller/controller-response.model";
import { FlushCountersResponse } from "../../api-models/flush/flush-response.model";
import { DateUtilService } from "../../../core/utils/date-util.service";
import { environment as env } from "../../../../environments/environment";
import { UsersAdminService } from "../users/users.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/status.service";
import * as i2 from "../../../api/services/history.service";
import * as i3 from "../../../api/services/configuration.service";
import * as i4 from "./controller-util.service";
import * as i5 from "ng-trio-infra";
import * as i6 from "../users/users.service";
var MockMode = true;
var ControllerService = /** @class */ (function () {
    function ControllerService(controllerStatusService, historyService, controllerCongig, ctrlrUtilService, logService, usersService) {
        var _this = this;
        this.controllerStatusService = controllerStatusService;
        this.historyService = historyService;
        this.controllerCongig = controllerCongig;
        this.ctrlrUtilService = ctrlrUtilService;
        this.logService = logService;
        this.usersService = usersService;
        this.dateUtil = new DateUtilService();
        this.selectedTenantId = env.tenant.id;
        this.controllers = new ReplaySubject(null);
        this.params = {};
        this.paramsStatus = {};
        // paramsHistory: HistoryService.ControllersHistoryAlarmsGetParams = {};
        // paramsHistoryAlarmByController: HistoryService.ControllersHistoryAlarmsByControllerIdGetParams = {};
        this.paramsStatusByControllerId = {};
        // paramsHistoryFlushByController: HistoryService.ControllersHistoryFlushByControllerIdGetParams = {};
        this.paramsStatusAll = {};
        this.paramsActiveAlarms = {};
        this.usersService.getCurrentTenant$.subscribe(function (tenant) {
            if (tenant) {
                _this.selectedTenantId = tenant.id;
            }
        });
    }
    ControllerService.prototype.setController = function (controller) {
        this._controller = controller;
    };
    ControllerService.prototype.getAdminControllers = function (limit, offset, search, filterType, country, thresholdExceeded, sortBy) {
        if (!MockMode) {
            // return ControllerMockUtil.getAdminControllers();
        }
        else {
            // if (
            //   limit !== "" &&
            //   offset !== "" &&
            //   limit !== undefined &&
            //   offset !== undefined
            // ) {
            //   const pageSize = $("select[name=controllersTable_length]").val();
            //   if (pageSize != limit) {
            //     this.params.limit = pageSize.toString();
            //     this.paramsStatusAll.limit = pageSize.toString();
            //   } else {
            //     this.params.limit = limit.toString();
            //     this.paramsStatusAll.limit = limit.toString();
            //   }
            //   this.params.offset = offset.toString();
            //   this.paramsStatusAll.offset = offset;
            // }
            if (limit !== "" && offset !== "") {
                this.params.limit = limit;
                this.paramsStatusAll.limit = limit;
                this.params.offset = offset;
                this.paramsStatusAll.offset = offset;
            }
            // switch (sortBy) {
            //   case "Serial Number":
            //     sortBy = "serialNumber";
            //     break;
            //   case "Filter Type":
            //     sortBy = "filterType";
            //     break;
            //   case "Connection Mode":
            //     sortBy = "connectionMode";
            //     break;
            //   case "Fw Version":
            //     sortBy = "fwVersion";
            //     break;
            //   default:
            //     sortBy = "serialNumber";
            // }
            this.params.filterType = filterType;
            this.params.country = country;
            this.params.orderBy = sortBy;
            this.params.search = search;
            this.paramsStatusAll.search = search;
            this.paramsStatusAll.filterType = filterType;
            this.paramsStatusAll.country = country;
            this.paramsStatusAll.orderBy = sortBy;
            this.paramsStatusAll.includeInfo = true;
            if (thresholdExceeded === "true") {
                this.paramsStatusAll.thresholdExceeded = true;
            }
            else if (thresholdExceeded === "false") {
                this.paramsStatusAll.thresholdExceeded = false;
            }
            else {
                delete this.paramsStatusAll.thresholdExceeded;
            }
            this.paramsStatusAll.controllerAccess = ["managed"];
            this.paramsStatusAll.tenantId = this.selectedTenantId;
            this.params.tenantId = this.selectedTenantId;
            this.controllers.next(this.controllerStatusService.ControllersStatusGET(this.paramsStatusAll));
            return this.controllerStatusService
                .ControllersStatusGET(this.paramsStatusAll)
                .map(function (apiPagedCtrlrs) {
                return {
                    offset: apiPagedCtrlrs.data.pagination.offset,
                    count: apiPagedCtrlrs.data.pagination.count,
                    totalCount: apiPagedCtrlrs.data.pagination.totalCount,
                    controllers: apiPagedCtrlrs.data.data
                        // .filter(
                        //   (controller) =>
                        //     controller.info !== null && controller.status !== null
                        // )
                        .map(function (apiCtrlr) {
                        var controllerResp = new ControllerResponse({ data: apiCtrlr });
                        return controllerResp.controller;
                    }),
                };
            });
        }
    };
    ControllerService.prototype.getActiveAlerts = function (limit, offset, filterType, country, alert) {
        if (!MockMode) {
            // return ControllerMockUtil.getAdminControllers();
        }
        else {
            if (limit !== "" && offset !== "") {
                this.paramsActiveAlarms.limit = limit;
                this.paramsActiveAlarms.offset = offset;
            }
            this.paramsActiveAlarms.filterType = filterType;
            this.paramsActiveAlarms.country = country;
            this.paramsActiveAlarms.alarmType = alert;
            this.paramsActiveAlarms.controllerAccess = ["managed"];
            this.paramsActiveAlarms.tenantId = this.selectedTenantId;
            return this.controllerStatusService.ControllersActivealarmsGET(this.paramsActiveAlarms);
        }
    };
    ControllerService.prototype.getUnregisteredControllersActiveAlerts = function (limit, offset, filterType, country, alert) {
        if (!MockMode) {
            // return ControllerMockUtil.getAdminControllers();
        }
        else {
            if (limit !== "" && offset !== "") {
                this.paramsActiveAlarms.limit = limit;
                this.paramsActiveAlarms.offset = offset;
            }
            this.paramsActiveAlarms.filterType = filterType;
            this.paramsActiveAlarms.country = country;
            this.paramsActiveAlarms.alarmType = alert;
            this.paramsActiveAlarms.controllerAccess = ["managed"];
            this.paramsActiveAlarms.tenantId = this.selectedTenantId;
            return this.controllerStatusService.UnregisteredControllersActivealarmsGET(this.paramsActiveAlarms);
        }
    };
    ControllerService.prototype.getControllerAbout = function (id) {
        var _this = this;
        this.paramsStatusByControllerId.controllerId = id;
        this.paramsStatusByControllerId.includeInfo = true;
        this.paramsStatusByControllerId.tenantId = this.selectedTenantId;
        var getControllerAbout$ = this.controllerStatusService.ControllersStatusByControllerIdGET(this.paramsStatusByControllerId);
        return getControllerAbout$.pipe(map(function (apiAbout) {
            return {
                deviceSN: apiAbout.data.info.controllerSN,
                deviceId: apiAbout.data.controllerId,
                fwVersion: {
                    number: apiAbout.data.info.version.fwVersion || null,
                },
                hwVersion: apiAbout.data.info.version.hwVersion || null,
                installDate: _this.dateUtil.parseISOString(apiAbout.data.info.version.installationDate) || null,
                totalFlush: apiAbout.data.status.flush.count || null,
                country: apiAbout.data.info.location.country || null,
            };
        }));
    };
    ControllerService.prototype.getController = function (id) {
        var _this = this;
        this.paramsStatusByControllerId.controllerId = id;
        this.paramsStatusByControllerId.includeInfo = true;
        this.paramsStatusByControllerId.tenantId = this.selectedTenantId;
        var ctrlrStatusObs = this.controllerStatusService.ControllersStatusByControllerIdGET(this.paramsStatusByControllerId);
        var ctrlrflushCountersObs = this.getFlushCounters(id);
        var ctrlrflushCyclesObs = this.historyService.ControllersHistoryFlushSummaryByControllerIdGet({
            controllerId: id,
            timeSpan: "week",
            tenantId: this.selectedTenantId,
        });
        return combineLatest(ctrlrStatusObs, ctrlrflushCountersObs, ctrlrflushCyclesObs)
            .pipe(map(function (results) { return ({
            status: results[0],
            flushCounter: results[1],
            flushCycles: results[2],
        }); }))
            .map(function (infoStatus) {
            var info = infoStatus.status.data.info;
            var status = infoStatus.status.data.status;
            var flushCounters = infoStatus.flushCounter.counters;
            var flushCycles = infoStatus.flushCycles.data.aggregations;
            var flushCyclesWithDate = [];
            flushCycles.forEach(function (element) {
                flushCyclesWithDate.push({
                    date: _this.dateUtil.parseISOString(element.aggregatedFrom),
                    dp: element.dp,
                    manual: element.manual,
                    preset: element.preset,
                    interval: element.interval,
                });
            });
            var ctrlr = {
                // TODO: Extract id from ApiControllerInfo
                id: id,
                name: info.siteName,
                owner: info.owner,
                filterSN: info.filterSN,
                filterationDegree: info.filterationDegree,
                workingPressure: info.workingPressure.toString(),
                waterSource: info.waterSource,
                flowRate: info.flowRate,
                configSynced: status.isConfigurationSynced,
                batteryOrPowerSource: status.batteryLevel,
                isConnected: status.isConnected,
                controllerSN: info.controllerSN,
                communicationMode: status.communicationMode.toLowerCase() ===
                    CommunicationMode.Online.toLowerCase()
                    ? CommunicationMode.Online
                    : CommunicationMode.Offline,
                activeAlarms: status.activeAlarms,
                description: info.siteName,
                filterType: info.filterType,
                operationMode: _this.ctrlrUtilService.parseOperationMode(status.rtuAddress),
                connectionStrength: status.connectionSignal,
                imgUrl: "/assets/img/controller/small-farm.jpg",
                isFlushing: status.flush.isFlushing ? true : false,
                lastSyncInfo: null,
                batteryLevel: status.batteryLevel,
                location: {
                    country: info.location.country,
                    longitude: info.location.longitude,
                    latitude: info.location.latitude,
                },
                version: {
                    installationDate: _this.dateUtil.parseISOString(info.version.installationDate),
                    hwVersion: info.version.hwVersion,
                    fwVersion: info.version.fwVersion,
                },
                pressure: {
                    inlet: status.pressure.inlet,
                    dp: status.pressure.dp,
                    outlet: status.pressure.outlet,
                },
                flushCounters: {
                    DP: flushCounters.DP,
                    preset: flushCounters.preset,
                    interval: flushCounters.interval,
                    manual: flushCounters.manual,
                    total: flushCounters.total,
                    antifreeze: flushCounters.antifreeze,
                    lastResetTime: flushCounters.lastResetTime,
                },
                flushCycles: flushCyclesWithDate,
                flush: {
                    isFlushing: status.flush.isFlushing,
                    lastFlushDate: _this.dateUtil.parseISOString(status.flush.lastFlush),
                    lastFlushReason: status.flush.lastFlushReason,
                    dpBefore: status.flush.dpBefore,
                    inletBefore: status.flush.inletBefore,
                    outletBefore: status.flush.outletBefore,
                    dpAfter: status.flush.dpAfter,
                    total: status.flush.count,
                },
                lastCommunication: _this.dateUtil.parseISOString(status.lastSeen),
                nextCommunication: _this.dateUtil.parseISOString(status.nextConnection),
                isActive: status.isActive,
                isBatteryPaused: null,
                extPaused: status.extPaused,
            };
            if (status.communicationMode.toLowerCase() ===
                CommunicationMode.Online.toLowerCase()) {
                var lastSyncOnline = {
                    inlet: status.pressure.inlet,
                    dp: status.pressure.dp,
                    timeFromLastFlush: status.flush.lastFlush,
                    lastFlushCause: status.flush.lastFlushReason,
                };
                ctrlr.lastSyncInfo = lastSyncOnline;
            }
            else {
                var lastSyncOffline = {
                    lastCommunication: _this.dateUtil.parseISOString(status.lastSeen),
                    nextCommunication: _this.dateUtil.parseISOString(status.nextConnection),
                    lastReceivedDp: status.pressure.dp.toString(),
                    configParamsSynced: status.isConfigurationSynced,
                };
                ctrlr.lastSyncInfo = lastSyncOffline;
            }
            return ctrlr;
        });
    };
    ControllerService.prototype.getControllers = function (withInfo) {
        if (withInfo === void 0) { withInfo = true; }
        this.logService.info(ControllerService.TAG, "Getting controllers");
        return this.controllerStatusService
            .ControllersStatusGET({
            includeInfo: withInfo,
            controllerAccess: ["managed"],
            tenantId: this.selectedTenantId,
        })
            .pipe(take(1), map(function (payload) {
            return new ControllersResponse(payload);
        }), catchError(function (err) {
            // Server Error
            return of(new ControllersResponse(err.error || null));
        }));
    };
    ControllerService.prototype.getControllerSettings = function (id) {
        var getConfigs$ = this.controllerCongig
            .ControllersConfigurationsByControllerIdGet({
            controllerId: id,
            tenantId: this.selectedTenantId,
        })
            .pipe(take(1));
        return getConfigs$.pipe(map(function (apiResponse) {
            var getSettingsResponse = new ControllerSettingsResponse(apiResponse);
            return getSettingsResponse;
        }));
    };
    ControllerService.prototype.getControllersSummary = function () {
        return this.historyService.ControllersHistorySummaryGet();
    };
    ControllerService.prototype.getUnregisteredControllersSummary = function () {
        return this.historyService.UnregisteredControllersHistorySummaryGet();
    };
    ControllerService.prototype.getControllerStatic = function () {
        return this._controller;
    };
    ControllerService.prototype.getFlushCounters = function (id) {
        this.logService.info("ControllerFlushService", "Getting flush counters..");
        var getFlushCounter$ = this.controllerStatusService
            .ControllersFlushCountersByControllerIdGET({
            tenantId: this.selectedTenantId,
            controllerId: id,
        })
            .pipe(take(1));
        return getFlushCounter$.pipe(map(function (apiResponse) {
            var flushCountersResp = new FlushCountersResponse(apiResponse);
            return flushCountersResp;
        }));
    };
    ControllerService.prototype.getUnregisteredControllers = function (_a) {
        var limit = _a.limit, offset = _a.offset, filterType = _a.filterType, orderBy = _a.orderBy, search = _a.search, country = _a.country;
        this.logService.info("ControllerFlushService", "Getting unregistered controllers...");
        this.params.limit = limit;
        this.paramsStatusAll.limit = limit;
        this.params.offset = offset;
        this.paramsStatusAll.offset = offset;
        this.params.filterType = filterType;
        this.paramsStatusAll.filterType = filterType;
        // this.params.orderBy = orderBy as "serialNumber" | "filterType" | "fwVersion"
        // this.paramsStatusAll.orderBy = orderBy as "serialNumber" | "filterType" | "fwVersion";
        this.params.search = search;
        this.paramsStatusAll.search = search;
        // this.params.country = country;
        // this.paramsStatusAll.country = country;
        var getUnregisteredControllers$ = this.controllerStatusService.UnregisteredControllersGET(this.paramsStatusAll)
            .pipe(take(1), map(function (payload) { return new UnregisteredControllersResponse(payload); }));
        return getUnregisteredControllers$;
    };
    ControllerService.TAG = "[ControllerService]";
    ControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ControllerService_Factory() { return new ControllerService(i0.inject(i1.StatusService), i0.inject(i2.HistoryService), i0.inject(i3.ConfigurationService), i0.inject(i4.ControllerUtilService), i0.inject(i5.LoggerService), i0.inject(i6.UsersAdminService)); }, token: ControllerService, providedIn: "root" });
    return ControllerService;
}());
export { ControllerService };

import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { LoggerService } from 'ng-trio-infra';
import { AuthService } from '../../authentication/auth.service';


@Injectable()

export class GlobalErrorHandler implements ErrorHandler {

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) { }

  handleError(error: (Error | HttpErrorResponse) & { error?: any }) {

    const errorService = this.injector.get(ErrorService);
    const authService = this.injector.get(AuthService);
    const logger = this.injector.get(LoggerService);

    let message: string = '';
    let stackTrace: string = '';

    if (error instanceof HttpErrorResponse) {
      // Server Error
      const userNotFound = error.error && error.error.errors && error.error.errors[0].description.includes('user') && error.error.errors[0].description.includes('not found');
      if (userNotFound) authService.logout();
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    // If server logging is enabled & server min log level >= Error,
    // the error will be logged to server
    logger.error(message, stackTrace);
  }
}

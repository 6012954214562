/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";
import { Observable as __Observable } from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";

import { Inline_response_200_17 } from "../models/inline-_response-_200_17";
import { Inline_response_200_18 } from "../models/inline-_response-_200_18";
import { Inline_response_200_19 } from "../models/inline-_response-_200_19";
import { Inline_response_200_20 } from "../models/inline-_response-_200_20";
import { Inline_response_200_21 } from "../models/inline-_response-_200_21";
import { AnalogInputData_inner } from "../models/analog-input-data-_inner";
import { WaterMeterData } from "../models/water-meter-data";
import { Inline_response_200_23 } from "../models/inline-_response-_200_23";
import { Inline_response_200_24 } from "../models/inline-_response-_200_24";
import { Inline_response_200_history_dp } from "../models/inline-_response-_200_history_dp";
@Injectable({
  providedIn: "root",
})
class HistoryService extends __BaseService {
  static readonly ControllersHistoryFlushSummaryByControllerIdGetPath =
    "/controllers/{controller_id}/history/flush/summary";
  static readonly ControllersHistoryFlushByControllerIdGetPath =
    "/controllers/{controller_id}/history/flush";
  static readonly ControllersHistoryFlushGetPath = "/controllers/history/flush";
  static readonly ControllersHistoryAlarmsByControllerIdGetPath =
    "/controllers/{controller_id}/history/alarms";
  static readonly ControllersHistoryAlarmsGetPath =
    "/controllers/history/alarms";
  static readonly ControllersHistoryAlarmsSummaryGetPath =
    "/controllers/history/alarms/summary";
  static readonly ControllersHistorySummaryGetPath =
    "/controllers/history/summary";
  static readonly ControllersHistoryAnaloginputByControllerIdAndInputNumberGetPath =
    "/controllers/{controller_id}/history/analoginput/{input_number}";
  static readonly ControllersHistoryWatermetersIrrigationByControllerIdGetPath =
    "/controllers/{controller_id}/history/watermeters/irrigation";

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * get history of flush activations. Either timeSpan or startDate+endDate are required.
   * @param params The `HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams` containing the following parameters:
   *
   * - `controller_id`: id of controller
   *
   * - `timeSpan`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return flush history
   */
  ControllersHistoryFlushSummaryByControllerIdGetResponse(
    params: HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_17>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.timeSpan != null)
      __params = __params.set("timeSpan", params.timeSpan.toString());
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/controllers/${params.controllerId}/history/flush/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_17>;
      })
    );
  }
  /**
   * get history of flush activations. Either timeSpan or startDate+endDate are required.
   * @param params The `HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams` containing the following parameters:
   *
   * - `controller_id`: id of controller
   *
   * - `timeSpan`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return flush history
   */
  ControllersHistoryFlushSummaryByControllerIdGet(
    params: HistoryService.ControllersHistoryFlushSummaryByControllerIdGetParams
  ): __Observable<Inline_response_200_17> {
    return this.ControllersHistoryFlushSummaryByControllerIdGetResponse(
      params
    ).pipe(__map((_r) => _r.body as Inline_response_200_17));
  }

  /**
   * get history of flush activations. no offset/limit. If response too large - will fail.
   * @param params The `HistoryService.ControllersHistoryFlushByControllerIdGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * - `search`:
   *
   * - `flushReason`: restricted to staticcontent/flushreasons list
   *
   * - `deviceOwner`:
   *
   * @return flush history
   */
  ControllersHistoryFlushByControllerIdGetResponse(
    params: HistoryService.ControllersHistoryFlushByControllerIdGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_18>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    (params.flushReason || []).forEach((val) => {
      if (val != null)
        __params = __params.append("flushReason", val.toString());
    });
    (params.deviceOwner || []).forEach((val) => {
      if (val != null)
        __params = __params.append("deviceOwner", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/history/flush`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_18>;
      })
    );
  }
  /**
   * get history of flush activations. no offset/limit. If response too large - will fail.
   * @param params The `HistoryService.ControllersHistoryFlushByControllerIdGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * - `search`:
   *
   * - `flushReason`: restricted to staticcontent/flushreasons list
   *
   * - `deviceOwner`:
   *
   * @return flush history
   */
  ControllersHistoryFlushByControllerIdGet(
    params: HistoryService.ControllersHistoryFlushByControllerIdGetParams
  ): __Observable<Inline_response_200_18> {
    return this.ControllersHistoryFlushByControllerIdGetResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_18)
    );
  }

  /**
   * get history for all controllers flush activations. no offset/limit. If response too large - will fail.
   * @param params The `HistoryService.ControllersHistoryFlushGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `search`:
   *
   * - `flushReason`: restricted to staticcontent/flushreasons list
   *
   * - `deviceOwner`:
   *
   * @return flush history
   */
  ControllersHistoryFlushGetResponse(
    params: HistoryService.ControllersHistoryFlushGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_18>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    (params.flushReason || []).forEach((val) => {
      if (val != null)
        __params = __params.append("flushReason", val.toString());
    });
    (params.deviceOwner || []).forEach((val) => {
      if (val != null)
        __params = __params.append("deviceOwner", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/history/flush`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_18>;
      })
    );
  }
  /**
   * get history for all controllers flush activations. no offset/limit. If response too large - will fail.
   * @param params The `HistoryService.ControllersHistoryFlushGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `search`:
   *
   * - `flushReason`: restricted to staticcontent/flushreasons list
   *
   * - `deviceOwner`:
   *
   * @return flush history
   */
  ControllersHistoryFlushGet(
    params: HistoryService.ControllersHistoryFlushGetParams
  ): __Observable<Inline_response_200_18> {
    return this.ControllersHistoryFlushGetResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_18)
    );
  }

  /**
   * get history of alarms
   * @param params The `HistoryService.ControllersHistoryAlarmsByControllerIdGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * - `search`:
   *
   * - `deviceOwner`:
   *
   * - `country`:
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsByControllerIdGetResponse(
    params: HistoryService.ControllersHistoryAlarmsByControllerIdGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_19>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());

    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());
    (params.deviceOwner || []).forEach((val) => {
      if (val != null)
        __params = __params.append("deviceOwner", val.toString());
    });
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.alarmType || []).forEach((val) => {
      if (val != null) __params = __params.append("alarmType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/history/alarms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_19>;
      })
    );
  }
  /**
   * get history of alarms
   * @param params The `HistoryService.ControllersHistoryAlarmsByControllerIdGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * - `search`:
   *
   * - `deviceOwner`:
   *
   * - `country`:
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsByControllerIdGet(
    params: HistoryService.ControllersHistoryAlarmsByControllerIdGetParams
  ): __Observable<Inline_response_200_19> {
    return this.ControllersHistoryAlarmsByControllerIdGetResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_19)
    );
  }

  /**
   * get alarms history for all controllers
   * @param params The `HistoryService.ControllersHistoryAlarmsGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `search`:
   *
   * - `deviceOwner`:
   *
   * - `country`:
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsGetResponse(
    params: HistoryService.ControllersHistoryAlarmsGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_19>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    if (params.search != null)
      __params = __params.set("search", params.search.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());
    (params.deviceOwner || []).forEach((val) => {
      if (val != null)
        __params = __params.append("deviceOwner", val.toString());
    });
    (params.country || []).forEach((val) => {
      if (val != null) __params = __params.append("country", val.toString());
    });
    (params.alarmType || []).forEach((val) => {
      if (val != null) __params = __params.append("alarmType", val.toString());
    });
    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/history/alarms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_19>;
      })
    );
  }
  /**
   * get alarms history for all controllers
   * @param params The `HistoryService.ControllersHistoryAlarmsGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `search`:
   *
   * - `deviceOwner`:
   *
   * - `country`:
   *
   * - `alarmType`: restricted to staticcontent/alarmtypes options
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsGet(
    params: HistoryService.ControllersHistoryAlarmsGetParams
  ): __Observable<Inline_response_200_19> {
    return this.ControllersHistoryAlarmsGetResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_19)
    );
  }

  /**
   * get alarms history for all controllers
   * @param params The `HistoryService.ControllersHistoryAlarmsSummaryGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsSummaryGetResponse(
    params: HistoryService.ControllersHistoryAlarmsSummaryGetParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_20>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    (params.controllerAccess || []).forEach((val) => {
      if (val != null)
        __params = __params.append("controllerAccess", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/history/alarms/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_20>;
      })
    );
  }
  /**
   * get alarms history for all controllers
   * @param params The `HistoryService.ControllersHistoryAlarmsSummaryGetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return alarm history
   */
  ControllersHistoryAlarmsSummaryGet(
    params: HistoryService.ControllersHistoryAlarmsSummaryGetParams
  ): __Observable<Inline_response_200_20> {
    return this.ControllersHistoryAlarmsSummaryGetResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_20)
    );
  }

  /**
   * get summary data of all my controllers
   * @return summary
   */
  ControllersHistorySummaryGetResponse(): __Observable<
    __StrictHttpResponse<Inline_response_200_21>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/history/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_21>;
      })
    );
  }

    /**
   * get summary data of all my controllers
   * @return summary
   */
    UnregisteredControllersHistorySummaryGetResponse(): __Observable<
    __StrictHttpResponse<Inline_response_200_21>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    //TODO remove mocks once implemented on server
    const mocks = {body: { errors: null, status: {code: 200, result: 'success'}, data: {
      "totalControllers": 20,
      "addedTodayCount": 10,
      "addedThisWeekCount": 10,
      "addedThisMonthCount": 10,
      "lastCountryAdded": "Israel",
      "shareControllersCount": 2,
      "onlineControllersCount": 0,
      "offlineControllersCount": 0,
      "connectedControllersCount": 2,
      "masterControllersCount": 0,
      "slaveControllersCount": 0,
      "comControllersCount": 0
    } } };
    const obsMock = __Observable.of(mocks)
    return obsMock.pipe(__map((_r) => {
      return _r as __StrictHttpResponse<Inline_response_200_21>;
      })
    )

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/history/summary`, //TODO change url
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_21>;
      })
    );
  }

  /**
   * get summary data of all my controllers
   * @return summary
   */
  ControllersHistorySummaryGet(): __Observable<Inline_response_200_21> {
    return this.ControllersHistorySummaryGetResponse().pipe(
      __map((_r) => _r.body as Inline_response_200_21)
    );
  }

  UnregisteredControllersHistorySummaryGet(): __Observable<Inline_response_200_21> {
    return this.UnregisteredControllersHistorySummaryGetResponse().pipe(
      __map((_r) => _r.body as Inline_response_200_21)
    );
  }

  /**
   * get sequence of aggregated history for analog input. input number can be
   * @param params The `HistoryService.ControllersHistoryAnaloginputGETParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `interval`:
   *
   * - `input_number`: id of analog input
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * @return data
   */
  ControllersHistoryAnaloginputGETResponse(
    params: HistoryService.ControllersHistoryAnaloginputGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_23>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.interval != null)
      __params = __params.set("interval", params.interval.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/controllers/${params.controllerId}/history/analoginput/${params.inputNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_23>;
      })
    );
  }
  /**
   * get sequence of aggregated history for analog input. input number can be
   * @param params The `HistoryService.ControllersHistoryAnaloginputGETParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `interval`:
   *
   * - `input_number`: id of analog input
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * @return data
   */
  ControllersHistoryAnaloginputGET(
    params: HistoryService.ControllersHistoryAnaloginputGETParams
  ): __Observable<Inline_response_200_23> {
    return this.ControllersHistoryAnaloginputGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_23)
    );
  }

  /**
   * get sequence of aggregated history for irrigation water meter.
   * @param params The `HistoryService.ControllersHistoryWatermetersIrrigationGETParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `interval`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * @return data
   */
  ControllersHistoryWatermetersIrrigationGETResponse(
    params: HistoryService.ControllersHistoryWatermetersIrrigationGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_24>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.interval != null)
      __params = __params.set("interval", params.interval.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    if (params.tenantId != null)
      __params = __params.set("tenantId", params.tenantId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/controllers/${params.controllerId}/history/watermeters/irrigation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_24>;
      })
    );
  }
  /**
   * get sequence of aggregated history for irrigation water meter.
   * @param params The `HistoryService.ControllersHistoryWatermetersIrrigationGETParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `interval`:
   *
   * - `endDate`:
   *
   * - `controller_id`: id of controller
   *
   * @return data
   */
  ControllersHistoryWatermetersIrrigationGET(
    params: HistoryService.ControllersHistoryWatermetersIrrigationGETParams
  ): __Observable<Inline_response_200_24> {
    return this.ControllersHistoryWatermetersIrrigationGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_24)
    );
  }

  ControllersHistoryDpGETResponse(
    params: HistoryService.ControllersHistoryDpGETParams
  ): __Observable<__StrictHttpResponse<Inline_response_200_history_dp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.interval != null)
      __params = __params.set("interval", params.interval.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/controllers/${params.controllerId}/history/dp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Inline_response_200_history_dp>;
      })
    );
  }

  ControllersHistoryDpGET(
    params: HistoryService.ControllersHistoryDpGETParams
  ): __Observable<Inline_response_200_history_dp> {
    return this.ControllersHistoryDpGETResponse(params).pipe(
      __map((_r) => _r.body as Inline_response_200_history_dp)
    );
  }
}

module HistoryService {
  /**
   * Parameters for ControllersHistoryDpGETParams
   */
  export interface ControllersHistoryDpGETParams {
    startDate: string;
    interval: string;
    endDate: string;

    /**
     * id of controller
     */
    controllerId: string;
  }

  /**
   * Parameters for ControllersHistoryFlushSummaryByControllerIdGet
   */
  export interface ControllersHistoryFlushSummaryByControllerIdGetParams {
    /**
     * id of controller
     */
    controllerId: string;
    timeSpan?: "day" | "week" | "month";
    startDate?: string;
    endDate?: string;
    tenantId?: string;

  }

  /**
   * Parameters for ControllersHistoryFlushByControllerIdGet
   */
  export interface ControllersHistoryFlushByControllerIdGetParams {
    tenantId?: string;

    startDate?: string;
    endDate?: string;

    /**
     * id of controller
     */
    controllerId?: string;
    search?: string;

    /**
     * restricted to staticcontent/flushreasons list
     */
    flushReason?: Array<string>;
    deviceOwner?: Array<string>;
    controllerAccess?: Array<"owned" | "shared" | "managed">;
  }

  /**
   * Parameters for ControllersHistoryFlushGet
   */
  export interface ControllersHistoryFlushGetParams {
    startDate?: string;
    endDate?: string;
    search?: string;

    /**
     * restricted to staticcontent/flushreasons list
     */
    flushReason?: Array<string>;
    deviceOwner?: Array<string>;
    controllerAccess?: Array<"owned" | "shared" | "managed">;

    tenantId?: string;
  }

  /**
   * Parameters for ControllersHistoryAlarmsByControllerIdGet
   */
  export interface ControllersHistoryAlarmsByControllerIdGetParams {
    startDate?: string;
    endDate?: string;

    /**
     * id of controller
     */
    controllerId?: string;
    search?: string;
    deviceOwner?: Array<string>;
    country?: Array<string>;

    /**
     * restricted to staticcontent/alarmtypes options
     */
    alarmType?: Array<string>;
    controllerAccess?: Array<"owned" | "shared" | "managed">;
    tenantId?: string;
  }

  /**
   * Parameters for ControllersHistoryAlarmsGet
   */
  export interface ControllersHistoryAlarmsGetParams {
    startDate?: string;
    endDate?: string;
    search?: string;
    deviceOwner?: Array<string>;
    country?: Array<string>;

    /**
     * restricted to staticcontent/alarmtypes options
     */
    alarmType?: Array<string>;
    controllerAccess?: Array<"owned" | "shared" | "managed">;
    tenantId?: string;
  }

  /**
   * Parameters for ControllersHistoryAlarmsSummaryGet
   */
  export interface ControllersHistoryAlarmsSummaryGetParams {
    startDate?: string;
    endDate?: string;
    controllerAccess?: Array<"owned" | "shared" | "managed">;
    tenantId?: string;
  }

  /**
   * Parameters for ControllersHistoryAnaloginputByControllerIdAndInputNumberGet
   */
  export interface ControllersHistoryAnaloginputByControllerIdAndInputNumberGetParams {
    startDate: string;
    interval: string;

    /**
     * id of analog input
     */
    inputNumber: string;
    endDate: string;

    /**
     * id of controller
     */
    controllerId: string;
  }

  /**
   * Parameters for ControllersHistoryWatermetersIrrigationByControllerIdGet
   */
  export interface ControllersHistoryWatermetersIrrigationByControllerIdGetParams {
    startDate: string;
    interval: string;
    endDate: string;

    /**
     * id of controller
     */
    controllerId: string;
  }

  export interface ControllersHistoryAnaloginputGETParams {
    startDate: string;
    interval?: string;

    /**
     * id of analog input
     */
    inputNumber: string;
    endDate: string;

    /**
     * id of controller
     */
    controllerId: string;

    tenantId?: string;
  }

  /**
   * Parameters for ControllersHistoryWatermetersIrrigationGET
   */
  export interface ControllersHistoryWatermetersIrrigationGETParams {
    startDate: string;
    interval?: string;
    endDate: string;

    /**
     * id of controller
     */
    controllerId: string;
    tenantId?: string;
  }
}

export { HistoryService };

import { Injectable } from "@angular/core";
import {
  UsersService,
  HistoryService,
  StatusService,
} from "../../../api/services";

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  PagedAlarms,
  AlarmResponsePaged,
} from "../../api-models/alarm/paged-alarms";
import { Alarm } from "../../api-models/alarm/alarm";
import { AlarmsReports } from "../../api-models/alarm/alarms-reports";
import { AlarmReport } from "../../api-models/alarm/alarmReport";
import {
  AlertsResponse,
  ControllerAlertsResponse,
} from "../../api-models/alarm/alert-response.model";
import { take, map } from "rxjs/operators";
import { LoggerService } from "ng-trio-infra";
import { IAlertsService } from "./ialerts.service";
import { DateUtilService } from "../../../core/utils/date-util.service";
import { environment as env } from "../../../../environments/environment";
import { UsersAdminService } from "../users/users.service";

const MockMode = true;
@Injectable({
  providedIn: "root",
})
export class AlarmService implements IAlertsService {
  private dateUtil: DateUtilService = new DateUtilService();
  selectedTenantId: string = env.tenant.id;

  constructor(
    private logger: LoggerService,
    private apiStatusService: StatusService,
    private usersService: UsersAdminService,
    private alarmService: HistoryService
  ) {
    this.usersService.getCurrentTenant$.subscribe((tenant) => {
      if (tenant) {
        this.selectedTenantId = tenant.id;
      }
    });
  }

  paramsActiveAlarms: StatusService.ControllersActivealarmsGETParams = {};
  params: HistoryService.ControllersHistoryAlarmsGetParams = {};
  paramsAlertReport: HistoryService.ControllersHistoryAlarmsSummaryGetParams =
    {};

  getControllersAlarmsHistory(
    limit?: string,
    offset?: string,
    search?: string,
    client?: string[],
    country?: string[],
    alarmType?: any,
    from?: string,
    to?: string,
    sortBy?: any
  ): Observable<AlarmResponsePaged> {
    if (!MockMode) {
      // return ClientMockUtil.getAdminClients();
    } else {
      // this.params.limit = limit.toString();
      // this.params.offset = offset.toString();
      this.params.search = search;
      this.params.deviceOwner = client;
      this.params.country = country;
      this.params.alarmType = alarmType;
      this.params.startDate = from;
      this.params.endDate = to;
      this.params.controllerAccess = ["managed"];
      this.params.tenantId = this.selectedTenantId;

      // this.params.orderBy = sortBy;
      //  this.params.role = ["user"];
      return this.alarmService
        .ControllersHistoryAlarmsGet(this.params)
        .map((apiPagedAlrams) => {
          return {
            alarms: apiPagedAlrams.data.events.map((apiAlrm) => {
              return {
                controllerId: apiAlrm.controllerId,
                siteName: apiAlrm.siteName,
                type: apiAlrm.type,
                time: this.dateUtil.parseISOString(apiAlrm.time),
              } as Alarm;
            }),
            status: apiPagedAlrams.status,
            errors: apiPagedAlrams.errors,
          } as AlarmResponsePaged;
        });
    }
  }

  getAlarmsReport(from?: string, to?: string): Observable<AlarmsReports> {
    if (!MockMode) {
      // return ClientMockUtil.getAdminClients();
    } else {
      this.paramsAlertReport.startDate = from;
      this.paramsAlertReport.endDate = to;
      this.paramsAlertReport.controllerAccess = ["managed"];
      this.paramsAlertReport.tenantId = this.selectedTenantId;

      return this.alarmService
        .ControllersHistoryAlarmsSummaryGet(this.paramsAlertReport)
        .map((apiPagedAlrams) => {
          return {
            alarmsReports: apiPagedAlrams.data.map((apiAlrm) => {
              return {
                type: apiAlrm.type,
                percentage: apiAlrm.percentage,
              } as AlarmReport;
            }),
          } as AlarmsReports;
        });
    }
  }

  getAllActiveAlerts(): Observable<AlertsResponse> {
    this.paramsActiveAlarms.controllerAccess = ["managed"];
    this.paramsActiveAlarms.tenantId = this.selectedTenantId;
    return this.apiStatusService
      .ControllersActivealarmsGET(this.paramsActiveAlarms)
      .pipe(
        take(1),
        map((payload) => {
          const resp = new AlertsResponse("active", payload);
          this.logger.info(
            "[AlertsService]",
            "Received all active alerts",
            resp
          );
          return resp;
        })
      );
  }

  getAllUnregsiteredControllersActiveAlerts(): Observable<AlertsResponse> {
    this.paramsActiveAlarms.controllerAccess = ["managed"];
    this.paramsActiveAlarms.tenantId = this.selectedTenantId;
    return this.apiStatusService
      .UnregisteredControllersActivealarmsGET(this.paramsActiveAlarms)
      .pipe(
        take(1),
        map((payload) => {
          const resp = new AlertsResponse("active", payload);
          this.logger.info(
            "[AlertsService]",
            "Received all active alerts",
            resp
          );
          return resp;
        })
      );
  }

  getAllHistoryAlerts(
    from: string,
    to: string,
    search?: string,
    deviceOwner?: string[],
    country?: string[],
    alarmType?: any
  ): Observable<AlertsResponse> {
    // from.setHours(to.getHours() - 1);
    var requestParams: HistoryService.ControllersHistoryAlarmsGetParams = {
      startDate: from,
      endDate: to,
      alarmType: alarmType,
      controllerAccess: ["managed"],
      country: country,
      deviceOwner: deviceOwner,
      search: search,
      tenantId: this.selectedTenantId,
    };

    let getHistoryAlerts$ =
      this.alarmService.ControllersHistoryAlarmsGet(requestParams);
    return getHistoryAlerts$.pipe(
      take(1),
      map((apiResponse) => {
        const historyAlertsResp = new AlertsResponse("history", apiResponse);
        return historyAlertsResp;
      })
    );
  }

  getControllerActiveAlerts(id: string): Observable<ControllerAlertsResponse> {
    return this.apiStatusService
      .ControllersActivealarmsByControllerIdGET({
        controllerId: id,
        tenantId: this.selectedTenantId,
      })
      .pipe(
        take(1),
        map((payload) => {
          const resp = new ControllerAlertsResponse("active", payload);
          this.logger.info(
            "[AlertsService]",
            "Received controller " + id + " active alerts",
            resp
          );
          return resp;
        })
      );
  }
}

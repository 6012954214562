import { Injectable } from "@angular/core";
import {
  ListResponse_inner,
  ControllerFlushCounterInfo,
} from "../../../../../app/api/models";

import { Adapter } from "../../controller/iadapter.interface";
import { FlushCounters } from "../flush-counter";
import { DateUtilService } from "../../../../core/utils/date-util.service";

@Injectable()
export class FlushCountersAdapterService implements Adapter<FlushCounters> {
  private dateUtil: DateUtilService = new DateUtilService();

  adapt(item: ControllerFlushCounterInfo): FlushCounters {
    if (item) {
      return {
        DP: {
          value: item.counters.dp,
        },
        interval: {
          value: item.counters.interval,
        },
        manual: {
          value: item.counters.manual,
        },
        preset: {
          value: item.counters.preset,
        },
        antifreeze: {
          value: item.counters.AntiFreeze || 0,
        },
        total: item.counters.total,
        lastResetTime: this.dateUtil.parseISOString(
          item.counters.lastResetTime
        ),
      };
    }
    return null;
  }
}
